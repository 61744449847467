import React, { useContext, useState } from "react";

import { showModal } from "../store/actions";
import { StateContext } from "../store/context";
import { useGetPaymentsByChildCampaignId } from "../helpers/hooks";
import { DonateNowForm } from "./donateNowForm";
import styles from "./donationInfo.module.scss";
import DonorDetails from "./donorDetails";
import HelpModal from "./helpModal";
import Picture from "./picture";
import NoDonorsYet from "./noDonorsYet";

function MostGenerousDonors(props) {
  const slug = props.slug;

  const childCampaignId = props.childCampaignId;
  let generousData = useGetPaymentsByChildCampaignId(
    "amount",
    slug,
    childCampaignId
  );
  if (generousData && !Array.isArray(generousData)) {
    generousData = generousData.campaignPayments;
    props.setTopDonorsListLength(generousData.length);
  }
  if (generousData && generousData.length > 0) {
    return generousData.map(x => <DonorDetails donor={x} key={x.createdAt} />);
  }
  return <NoDonorsYet colorClass={"text-white"} text={"No Donors Yet"} />;
}

const ChildCampaignDonationInfo = ({
  childCampaign,
  banner,
  className,
  type,
}) => {
  const slug = childCampaign.parentCampaign.slug;
  const childCampaignId = childCampaign.contentful_id;
  const [donationListScrollable, setDonationListScrollable] = useState(false);

  const enableScroller = () => {
    setDonationListScrollable(true);
  };

  const { dispatch } = useContext(StateContext);
  const [topDonorsListLength, setTopDonorsListLength] = useState(0);

  return (
    <section
      className={`col-12 ${className} ${
        type && type === "subSection" ? " px-0 px-md-3 mt-5 mt-lg-0" : ""
      }`}
    >
      <h3
        className={`${
          type && type === "section"
            ? "section-title"
            : "font-30 font-weight-bolder"
        } text-center text-black`}
      >
        Donors List
      </h3>
      <div className={`row px-0 ${styles.mapDonorRow}`}>
        <div
          className={`col-12 col-lg-12 px-lg-0 ${
            type && type === "subSection" ? " px-0 px-md-3" : ""
          }`}
        >
          <div
            className={`${styles.donorListContainer} h-100 position-relative`}
          >
            <Picture
              image={banner[0].image}
              className={`abs-full img-default`}
            />
            <div
              className={`${styles.bgImgOverlay} abs-top-left w-100 h-100`}
            ></div>
            <div
              className={`col-12 px-0 tab-content border-darken-1 ${
                donationListScrollable === true ? styles.listScrollable : ""
              }`}
              id="myTabContent"
            >
              <div
                className={`col-12 px-0 table-responsive tab-pane fade active show ${styles.tabPane} ${styles.donorList}`}
                id="most-generous"
                role="tabpanel"
                aria-labelledby="most-generous-tab"
              >
                <MostGenerousDonors
                  slug={slug}
                  childCampaignId={childCampaignId}
                  setTopDonorsListLength={setTopDonorsListLength}
                />
              </div>
            </div>
            {!donationListScrollable && topDonorsListLength > 5 && (
              <div
                className={`${styles.viewMoreContainer} text-right position-relative`}
              >
                <button
                  className={`btn button-8BC540 ${styles.viewMoreButton} font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
                  onClick={enableScroller}
                  id="view-more"
                >
                  View more
                </button>
              </div>
            )}
          </div>
          <div
            className={`${styles.donateContainer} d-flex align-items-center`}
          >
            <span
              className={` ${styles.donateLabel} d-none d-lg-flex align-items-center font-20 mr-4`}
            >
              Come, let’s join hands
            </span>
            <button
              className={`btn button-8BC540 ${styles.captionButton} ${styles.footerbtn} font-16 fw-500 letter-spacing-019 width-fit-content text-uppercase`}
              id="donor-list-donate-now"
              type="button"
              onClick={() =>
                showModal(
                  dispatch,
                  () => (
                    <DonateNowForm
                      campaign={childCampaign.parentCampaign}
                      childCampaign={childCampaign}
                    />
                  ),
                  () =>
                    showModal(dispatch, () => (
                      <HelpModal campaign={childCampaign.parentCampaign} />
                    ))
                )
              }
            >
              Donate Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChildCampaignDonationInfo;
