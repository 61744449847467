import React from "react";
import styles from "./donationInfo.module.scss";
const IndiaMap = () => {
  return (
    <section className="col-12 col-lg-6">
      <div className={`col-12 col-lg-12 `}>
        <img
          src=" /images/indiaMap.png"
          className={styles.map}
          alt="India Map"
        />
        <small className="d-block text-center">More states coming soon...</small>
      </div>
    </section>
  );
};
export default IndiaMap;
