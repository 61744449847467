import { Link } from "gatsby";
import React, { useState } from "react";
import styles from "./events.module.scss";
import Picture from "./picture";

const EventsSection = props => {
  let [slideIndex, setSlideIndex] = useState(1);

  function returnDateOrMonth(date, day) {
    if (day === true) return date.split("/")[0];
    else return date.split("/")[1];
  }

  const handleClick = (e, element) => {
    const eventArticle = document.getElementById(element);
    if (!eventArticle) return;
    if (eventArticle.scrollIntoViewIfNeeded) {
      e.preventDefault();
      eventArticle.scrollIntoViewIfNeeded();
    } else if (eventArticle.scrollIntoView) {
      e.preventDefault();
      eventArticle.scrollIntoView();
    }
  };

  function slideToPreviousOrNextNews(next, previous, event) {
    if (next === true && slideIndex + 1 <= props.event.length) {
      setSlideIndex(++slideIndex);
      handleClick(event, `event-slide-${slideIndex}`);
    } else if (previous === true && slideIndex - 1 >= 1) {
      setSlideIndex(--slideIndex);
      handleClick(event, `event-slide-${slideIndex}`);
    } else {
      event.preventDefault();
    }
  }

  return (
    <section className={`col-12 ${styles.eventsSection}`}>
      <span className="pill-label pill-bg-white">EVENTS & MEETINGS</span>
      <h1 className="section-title text-white">Our Events</h1>
      <div
        id="events-carousel"
        className={`${styles.newsEventsCarousel} d-flex ${
          props.event.length === 2
            ? "justify-content-start justify-content-md-center "
            : props.event.length < 2
            ? "justify-content-center"
            : "justify-content-start"
        }`}
      >
        {props.event
          .sort((a, b) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1))
          .map((x, index) => {
            return (
              <article
                id={`event-slide-${index + 1}`}
                className={`${styles.newsEvent} shadow`}
                key={index}
              >
                <Link to={`/eventDetailPage/${x.eventSlug}`}>
                  <div
                    className={`${styles.newsImageDateContainer} position-relative`}
                  >
                    {x.eventImage && (
                      <div className="position-relative">
                        <Picture
                          image={x.eventImage}
                          className={`${styles.newsBackgroundImage} abs-top-left w-100 h-100`}
                        />
                        <div className="position-relative">
                          <Picture
                            image={x.eventImage}
                            className={styles.newsImage}
                          />
                        </div>
                      </div>
                    )}
                    <span
                      className={`${styles.newsDate} text-white position-absolute`}
                    >
                      <span className="d-block font-18 fw-500 text-center">
                        {returnDateOrMonth(x.eventDate, true)}
                      </span>
                      <span className="d-block font-12 font-weight-regular text-uppercase text-center">
                        {returnDateOrMonth(x.eventDate, false)}
                      </span>
                    </span>
                  </div>
                </Link>
                <div className={styles.titleSubtitleContainer}>
                  <Link to={`/eventDetailPage/${x.eventSlug}`}>
                    <h3 className="font-16 text-black letter-spacing-019 line-height-24px mb-0">
                      {x.eventTitle}
                    </h3>{" "}
                  </Link>
                  <p className="font-14 text-798585 letter-spacing-017 line-height-24px mb-0">
                    {x.subtitle}
                  </p>
                </div>
              </article>
            );
          })}
      </div>

      <div
        className={`${styles.carouselControl} d-flex justify-content-center align-items-center w-100`}
      >
        <a
          href="#event-slide-1"
          className="mr-2 h-100"
          onClick={e => {
            slideToPreviousOrNextNews(false, true, e);
          }}
        >
          <img
            src="/icons/arrow-left-white.svg"
            className={`${styles.navigationArrow} cursor-pointer`}
            alt="White Arrow Left"
          />
        </a>
        <span className={styles.slidesCountInfo}>
          Showing {slideIndex} of {props.event.length}
        </span>
        <a
          href="#event-slide-5"
          className="ml-2 h-100"
          onClick={e => {
            slideToPreviousOrNextNews(true, false, e);
          }}
        >
          <img
            src="/icons/arrow-right-white.svg"
            className={`${styles.navigationArrow} cursor-pointer`}
            alt="White Arrow Right"
          />
        </a>
      </div>
    </section>
  );
};

export default EventsSection;
