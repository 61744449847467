import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import ngoBackgroundImg from "../assets/bg-NGO.png";
import styles from "./ngoSection.module.scss";
import Picture from "./picture";
const NgoSection = props => {
  const { allContentfulPartnerNgo } = useStaticQuery(query);
  return (
    <section
      className={`col-12 ${styles.ngoContainer} mt-0 mt-lg-5 pt-0 pt-lg-3`}
    >
      <div
        className={`d-flex ${styles.ngoRow}`}
        style={{
          backgroundImage: `url(${ngoBackgroundImg})`,
        }}
      >
        <div className={styles.titleContent}>
          <p
            className={`${styles.ngoTitle} text-0066A2 font-weight-bold font-32 mb-0`}
          >
            NGO Partners
          </p>
        </div>
        {props.partners?.length > 0 ? (
          <div className={styles.ngoLogoContainer}>
            {props?.partners.map((x, index) => {
              return (
                <Link to={`/ngo-details/${x.slug}`} key={index}>
                  <Picture
                    image={x.displaylogoinhomepage ? x.homepagelogo : x.logo}
                    className={styles.ngoImage}
                  />
                </Link>
              );
            })}
          </div>
        ) : (
          <div className={styles.ngoLogoContainer}>
            {allContentfulPartnerNgo.nodes.map((x, index) => {
              return (
                <Link to={`/ngo-details/${x.slug}`} key={index}>
                  <Picture
                    image={x.displaylogoinhomepage ? x.homepagelogo : x.logo}
                    className={styles.ngoImage}
                  />
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default NgoSection;

const query = graphql`
  query {
    allContentfulPartnerNgo {
      nodes {
        slug
        logo {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
            fileName
          }
          title
        }
        homepagelogo {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
          file {
            url
            fileName
          }
          title
        }
        displaylogoinhomepage
      }
    }
  }
`;
