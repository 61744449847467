import React from "react";

const AboutSupportingCampaignerSection = ({ creatorName, appeal }) => {
  return (
    <section className={`col-12 section-padding my-0 bgF9FCFA`}>
      <div className="py-5">
        <span className="pill-label pill-bg-gradient">
          About Supporting Campaigner
        </span>
        <h1 className="section-title text-black">Appeal by {creatorName}</h1>
        <div className="text-center font-20 letterSpacing0 text-637285">
          <q>&nbsp;{appeal}&nbsp;</q>
        </div>
      </div>
    </section>
  );
};

export default AboutSupportingCampaignerSection;
