import React from "react";
import styles from "./implementationEcosystem.module.scss";

const ImplementationEcosystem = () => {
  return (
    <section className={`col-12 ${styles.sectionBgImage}`}>
      <div className="align-items-center">
        <span className="pill-label pill-bg-white">TRUST AND TRANSPARENCY</span>
        <h1 className="section-title text-white">Implementation Ecosystem</h1>
        <img
          src="/images/Infographic.svg"
          className={`${styles.ecosystemImage} d-block`}
          alt="ecosystem"
        />
      </div>
    </section>
  );
};

export default ImplementationEcosystem;
