import React from "react";

const ChildCampaignTag = ({ creatorName, ngo, className }) => {
  return (
    <section className={`fullWidth bg8BC540 ${className}`}>
      <p className="text-center font-18 font-weight-light text-white mb-0 py-4 py-md-2 px-3 px-lg-0">
        This is a supporting fundraiser by {creatorName}. Contributions made to
        this fundraiser will go towards the parent fundraiser by {ngo.ngoName}
      </p>
    </section>
  );
};

export default ChildCampaignTag;
